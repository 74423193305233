var BASE_CONFIG = {
    form: {
        formMandatoryFields: ['first_last_name', 'email', 'street', 'postalcode', 'city', 'template'],
        formErrorMessages: {
            first_last_name: "Bitte geben Sie Ihren Vor- und Nachnamen an.",
            email: "Bitte geben Sie eine gültige E-Mail-Adresse an.",
            street: "Bitte geben Sie eine Straße an.",
            postalcode: "Bitte geben Sie eine Postleitzahl an.",
            city: "Bitte geben Sie einen Ort an.",
            template: "Bitte w&auml;hlen Sie ein Design aus.",
            phone: "Bitte geben Sie Ihre Telefonnummer ohne Leer- oder Sonderzeichen an.",
            msg: "Bitte geben Sie Ihre Nachricht an."
        }
    }
};

(function () {
    'use strict';

    var obj,
        formAction = {
        init: function () {

            obj = this;

            // get device
            obj.activeDevice = $('*[data-action="get-device"]:visible').prop('id');

            /**
             * set focus on first input
             */
            var form = $('form[name="contact"]');

            if ( obj.activeDevice === 'isDesktop' ) { // set focus on first input only on desktop
                form.find('input[type="text"]').first().focus().scrollTo();
            }


            /**
             * set mandatory fields and validation rules
             */
            var mandatoryFields = BASE_CONFIG.form.formMandatoryFields,
                validationRules = {};

            $.each(mandatoryFields, function (key, field) {

                // set mandatory fields
                form.find('label[for="' + field + '"]').addClass('mandatory');


                // set validation rules
                if ( field === 'email') {
                    validationRules[field] = {
                        required: true,
                        email: true
                    };
                } else if ( field === 'phone' ) {
                    validationRules[field] = {
                        required: true,
                        digits: true
                    };
                } else {
                    validationRules[field] = 'required';
                }

            });


            /**
             * form validation
             */
            form.validate({
                submitHandler: function (form) {

                    var $this = $(form),
                        params = {
                            "form": $this,
                            "action": $this.prop('action'),
                            "input": $this.serialize()
                        };

                    obj.submitForm(params);

                },
                focusInvalid: false,
                invalidHandler: function (formular, validator) {

                    if (!validator.numberOfInvalids())
                        return;

                    $.scrollTo(form.parent().find('*[data-id="error-box"]'));
                },
                errorContainer: $('*[data-id="error-box"]'),
                errorLabelContainer: $('ul', $('*[data-id="error-box"]')),
                errorClass: 'has-error',
                wrapper: 'li',
                rules: validationRules,
                messages: BASE_CONFIG.form.formErrorMessages
            });


        },
        submitForm: function (data) {

            obj.form = data.form;
            obj.errorBox = obj.form.parent().find('*[data-id="error-box"]');

            $.ajax({
                type: 'POST',
                url: data.action,
                data: data.input,
                dataType: 'json'
            }).done(function (response) {

                if ( response.success ) { // SUCCESS

                    // hide form-box
                    // obj.form.parent().hide();

                    // replace email in text
                    var thanksBox = $('*[data-id="success-box"]'),
                        textLine = thanksBox.find('[data-special="email"]');

                    if ( !!response.userInput.email.length) {
                        textLine.html(textLine.text().replace('#email', ' (<span class="hint">' + response.userInput.email + '</span>)'));
                    } else {
                        textLine.html(textLine.text().replace('#email', ''));
                    }

                    // show thanks-box
                    // thanksBox.show();
                    $.fancybox({
                        href: '#formSuccessMsg',
                        type: 'inline',
                        maxWidth: 600,
                        afterShow: function () {
                            obj.clearForm();
                        }
                    });

                    // scroll to thanks-box
                    // $.scrollTo(thanksBox, {
                    //     offset: -50
                    // });

                } else { // ERROR

                    // show error msgs
                    var errorList = obj.errorBox.find('#errors');

                    if ( !$.isEmptyObject(response.errors) ) {

                        // remove old errors
                        errorList.empty();

                        // remove old input marks
                        $.each(obj.form.find('.error'), function (key, item) {
                            $(item).removeClass('error');
                        });

                        // append new errors
                        // mark input fields
                        $.each(response.errors, function (key, error) {

                            // append
                            errorList.append('<li>' + error + '</li>');

                            // mark
                            obj.form.find('*[name="' + key + '"]').addClass('error');

                        });

                        // show error box
                        obj.errorBox.show();

                        // scroll to error-box
                        $.scrollTo(obj.errorBox, {
                            offset: -50
                        });

                        // show error msgs
                        errorList.show();

                        // set focus on first input error field
                        obj.form.find('.error').first().focus();

                    }

                }

            });

        },
        clearForm: function () {
            obj.form.trigger('reset');
        }
    };

    var openPreview = {
        init: function (design, theme, menu) {

            this.url = 'https://www.psychotherapie-website.at/tpls/_default/index.php?';
            this.design = design;
            this.theme = theme;
            this.menu = menu;

            this.designMap = {
                'modern': 'Modern',
                'classic': 'Klassisch',
                'trendy': 'Trendig'
            }

            this.openBox();

        },
        openBox: function () {

            var _this = this;

            $.fancybox({
                href        : this.url + 'design=' + this.design + '&theme=' + this.theme,
                type        : 'iframe',
                maxWidth    : 1340,
                fitToView   : true,
                width       : '100%',
                height      : '100%',
                autoSize    : true,
                title       : 'loading',
                helpers     : {
                    title: {
                        type: 'inside'
                    }
                },
                afterShow  : function () {
                    _this.appendMenu();
                }
            });

        },
        appendMenu: function () {

            var menuStart = '<div class="popupMenu">',
                heading = '<div data-action="toggleMenu" data-dir="down"><h3>Wählen Sie aus fünf verschiedenen Farbvarianten des Designs "' + this.designMap[this.design] + '":</h3><a class="toggleMenu"><span class="glyphicon glyphicon-chevron-down"></span></a></div>',
                menuEnd = '</div>',
                titleBox = $('.fancybox-title');

            this.titleBox = titleBox;

            titleBox.html(menuStart + heading + this.menu + menuEnd);

            $.fancybox.update();
            $.fancybox.reposition();

            this.setActiveMenuItem();


        },
        loadTheme: function (theme) {

            var iframe = $('.fancybox-iframe'),
                updatedUrl = this.url + 'design=' + this.design + '&theme=' + theme;

            this.theme = theme;

            iframe.attr('src', updatedUrl);

            this.setActiveMenuItem();

        },
        setActiveMenuItem: function () {

            var menuItems = $('.popupMenu').find('a');

            // remove active class
            $.each(menuItems, function (key, item) {
                $(item).removeClass('active');
            });

            // set active class
            $('.popupMenu').find('a[data-theme="' + this.theme + '"]').addClass('active');

        },
        toggleMenu: function (dir) {

            // get device
            this.activeDevice = $('*[data-action="get-device"]:visible').prop('id');

            var newDir = ( dir === 'down' ) ? 'up' : 'down',
                sign = ( dir === 'down' ) ? '-' : '+',
                cssMap = {
                    'up': 'glyphicon-chevron-up',
                    'down': 'glyphicon-chevron-down'
                };

            var height;

            if ( this.activeDevice === 'isLargeDesktop' ) {
                height = ( dir === 'up' ) ? 170 : 60;
            } else if ( this.activeDevice === 'isDesktop' ) {
                height = ( dir === 'up' ) ? 281 : 60;
            } else if ( this.activeDevice === 'isTablet' ) {
                height = ( dir === 'up' ) ? 290 : 80;
            } else if (this.activeDevice === 'isMobile') {
                height = ( dir === 'up' ) ? 395 : 70;
            }

            this.titleBox.animate({
                height: height + 'px'
            }, 'fast', 'swing', function () {
                $.fancybox.update();
            });

            // set new direction
            $('*[data-action="toggleMenu"]').data('dir', newDir);
            $('*[data-action="toggleMenu"]').find('span').removeClass(cssMap[dir]);
            $('*[data-action="toggleMenu"]').find('span').addClass(cssMap[newDir]);

        }
    };


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // form actions

    if ( !!$('form').length ) {

        formAction.init();

    }

    // more Info

    $('body').on('click', '*[data-action="moreInfo"]', function (e) {

        e.preventDefault();

        var form = $('#top');

        // scroll to form
        $.scrollTo(form, {
            duration: 'slow',
            easing: 'swing'
        });

    });

    // open Preview

    $('body').on('click', '*[data-action="openPreview"]', function (e) {

        e.preventDefault();

        var $this = $(this),
            design = $this.data('design'),
            theme = $this.data('theme'),
            menu = $('*[data-menu="' + design + '"]').html();

        openPreview.init(design, theme, menu);

    });

    $('body').on('click', '*[data-action="loadTheme"]', function (e) {

        e.preventDefault();

        var $this = $(this),
            theme = $this.data('theme');

        openPreview.loadTheme(theme);

    });

    $('body').on('click', '*[data-action="toggleMenu"]', function (e) {

        e.preventDefault();

        var $this = $(this),
            dir = $this.data('dir');

        openPreview.toggleMenu(dir);

    });

}());